
.train-manage {
  min-height: calc(100vh - 120px);
  background: #f7f9fb;
  .header {
    span {
      font-size: 14px;
    }
  }
  .train-txt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333333;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-size: 20px;
    font-weight: 500;
    line-height: 1;
    margin: 20px 0;
    ::v-deep .el-button--primary {
      border-color: rgba(17, 34, 216, 1);
      background: rgba(17, 34, 216, 1);
    }
    .reset-train {
      border: 1px solid #fd4446;
      padding: 10px 11px;
      color: #fd4446;
      &:hover {
        color: #fd4446d9;
        background-color: #fff;
        border: 1px solid #fd4446d9;
      }
    }
  }
  .train-container {
    background: #fff;
    padding: 20px;
    min-height: calc(100vh - 210px);
    /*height: calc(100vh - 189px);*/
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .left-one {
      position: relative;
      left: 22.5%;
    }
    .left-two {
      position: relative;
      left: 47%;
    }
    .left-three {
      position: relative;
      left: 85%;
    }
    .pages-center {
      margin-top: 15px;
      text-align: right;
    }
  }
  .train-dialog-content {
    display: flex;
    flex-direction: column;
    line-height: 2;
  }
}
